import React from "react"
import './index.css'
export default function ContactsBlock() {
    return (
        <div className="phones">
            <ul>
                <li>
                    <a rel="nofollow" href="tg://resolve?domain=Erotic_Massage_Lviv">Telegram</a>
                    <a href="tel:+380(98)6684173">+380 (98) 668 41 73</a>
                </li>
                <li>
                    <a rel="nofollow" href="whatsapp://send?phone=+380963413434">WhatsApp</a>
                    <a href="tel:+380(98)6684173">+380 (98) 668 41 73</a>
                </li>
                <li>
                    <a rel="nofollow" href="viber://chat?number=380963413434">Viber</a>
                    <a href="tel:+380(98)6684173">+380 (98) 668 41 73</a>
                </li>
            </ul>
        </div>
    )
}