import React, { useContext } from "react"
import ContactsBlock from "../ContactsBlock"
import logo from "./logo.png"
import './index.css'
import { FormattedMessage } from 'react-intl'


export default function HeaderBlock() {


    return (
        <header>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <FormattedMessage id="header1" defaultMessage="Erotic massage in Lviv" >
                    {alt => <img src={logo} className="logotype" alt={alt} />}
                </FormattedMessage>
            </div>
            <div className="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h1><FormattedMessage id="header1" defaultMessage="Erotic massage in Lviv" /></h1>
                <span>18+</span> <span>24/7</span>
            </div>
            <div className="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <ContactsBlock></ContactsBlock>
                <a href="https://erotic-massage.lviv.ua/uk/"><FormattedMessage id="link1" /></a>

            </div>
        </header>
    )
}