import React from "react"
import './index.css'
import { ReactComponent as Line } from "./decor.svg"
import { FormattedMessage } from 'react-intl'
export default function FirstScreenBlock() {
    return (
        <section className="first-screen">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <h2><FormattedMessage id="header2" /></h2>
                <Line className="line-svg" />
                <FormattedMessage id="block1" />
            </div>
        </section>
    )
}