import React, { useContext, useState } from "react"
import './index.css'
import { Context } from "../Wrapper"
import { NavLink } from "react-router-dom";
import { FormattedMessage } from 'react-intl'

export default function MenuBlock(props: any) {
    const context = useContext(Context);
    const [status, setStatus] = useState(false);
    let menu = "menu";
    menu += status ? " activem" : " disacive";

    return (
        <nav>
            <button type="button" className="button" onClick={() => setStatus(!status)}>☰</button>
            <ul className={menu} onClick={() => setStatus(false)}>

                <li>
                    <select value={context.locale} onChange={context.selectLang} onClick={e => e.stopPropagation()}>
                        <option value="ru-RU">Русский</option>
                        <option value="uk-UA">Українська</option>
                    </select>
                </li>
            </ul>
        </nav>
    )
}
