import React from "react"
import './index.css'
import lady1 from './l1.jpg'
import lady2 from './l2.jpg'
import lady3 from './l3.jpg'
import { FormattedMessage } from 'react-intl'
export default function LadiesBlock() {
    return (
        <section className="col-12 ladies">
            <h3 className="like-header">
                <FormattedMessage id="eromassage" defaultMessage="Eromassage Lviv" />
            </h3>

            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 lady">
                <figure>
                    <img src={lady1} alt="erotic massage in lviv with Lady" />
                    <p>
                        <a href="tel:tel:+380986684173" className="form-button"> <FormattedMessage id="asktocall" defaultMessage="I want you! ;)" /></a>
                    </p>
                    <figcaption>Люба</figcaption>
                </figure>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 lady">
                <figure>
                    <img src={lady2} alt="erotic massage in lviv with Lady" />
                    <p>
                        <a href="tel:tel:+380986684173" className="form-button"><FormattedMessage id="asktocall" defaultMessage="I want you! ;)" /></a>
                    </p>
                    <figcaption>Надя</figcaption>
                </figure>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 lady">
                <figure>
                    <img src={lady3} alt="erotic massage in lviv with Lady" />
                    <p>
                        <a href="tel:tel:+380986684173" className="form-button"><FormattedMessage id="asktocall" defaultMessage="I want you! ;)" /></a>
                    </p>
                    <figcaption>Верочка</figcaption>
                </figure>
            </div>
            <div className="col-12">
                <a href="tel:tel:+380986684173" className="form-button"><FormattedMessage id="asktocall" defaultMessage="Call me!" /></a>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                <div className="container">
                    <FormattedMessage id="whatwegive" defaultMessage="Eromassage Lviv" />
                </div>
            </div>
        </section>
    )
}