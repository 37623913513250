import React, { useState } from 'react';
import { IntlProvider } from 'react-intl'
import Ukraine from '../../languages/uk.json'
import Russia from '../../languages/ru.json'

type ContextInterface = {
    locale: string;
    selectLang: (e: any) => void
}
export const Context = React.createContext({} as ContextInterface);
const local = navigator.language;
let realLang = local === "ru-RU" ? Russia : Ukraine;

const Wrapper = (props: { children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(realLang);
    function selectLang(e: { target: { value: any; }; }) {
        const newLocale = e.target.value;
        setLocale(newLocale);
        newLocale === "ru-RU" ? setMessages(Russia) : setMessages(Ukraine);
    }

    return (
        <Context.Provider value={{ locale, selectLang }}>
            <IntlProvider locale={locale} messages={messages}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    )
}

export default Wrapper;



/*

*/