import React from "react"
import './index.css'
import { FormattedMessage } from 'react-intl'
export default function PriceBlock() {
    return (
        <section className="price-block container">
            <h4 className="like-header"><FormattedMessage id="price" defaultMessage="Price" /></h4>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 price-inblock">
                <div>
                    <span className="price-inblock-before">с 8 до 21:00</span>
                    <a href="tel:+380986684173">
                        <span><FormattedMessage id="00" defaultMessage="Massage for mens" /></span>
                        <p><FormattedMessage id="000" defaultMessage="Massage for mens" /></p>
                        <span className="price">800 UAH</span>
                    </a>
                    <span className="price-inblock-after">40 min</span>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 price-inblock">
                <div>
                    <a href="tel:+380986684173">
                        <span><FormattedMessage id="11" defaultMessage="Massage for mens" /></span>
                        <p><FormattedMessage id="111" defaultMessage="Massage for mens" /></p>
                        <span className="price">1700 UAH +</span>
                    </a>
                    <span className="price-inblock-after">60 min</span>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 price-inblock">
                <div>
                    <span className="price-inblock-before">NEW!</span>
                    <a href="tel:+380986684173">
                        <span><FormattedMessage id="22" defaultMessage="Massage for mens" /></span>
                        <p><FormattedMessage id="222" defaultMessage="Massage for mens" /></p>
                        <span className="price">1700 UAH</span>
                    </a>
                    <span className="price-inblock-after">60 min</span>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 price-inblock">
                <div>
                    <a href="tel:+380986684173">
                        <span><FormattedMessage id="33" defaultMessage="Massage for mens" /></span>
                        <p><FormattedMessage id="333" defaultMessage="Massage for mens" /></p>
                        <span className="price">2500 UAH</span>
                    </a>
                    <span className="price-inblock-after">60 min</span>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 price-inblock">
                <div>
                    <a href="tel:+380986684173">
                        <span><FormattedMessage id="44" defaultMessage="Massage for mens" /></span>
                        <p><FormattedMessage id="444" defaultMessage="Massage for mens" /></p>
                        <span className="price">2500 UAH</span>
                    </a>
                    <span className="price-inblock-after">60 min</span>
                </div>
            </div>
            <div className="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4 price-inblock">
                <div>
                    <span className="price-inblock-before">BEST PRICE!</span>
                    <a href="tel:+380986684173">
                        <span><FormattedMessage id="55" defaultMessage="Massage for mens" /></span>
                        <p><FormattedMessage id="555" defaultMessage="Massage for mens" /></p>
                        <span className="price">10000 UAH</span>
                    </a>
                    <span className="price-inblock-after">480 min</span>
                </div>
            </div>


        </section>
    )
}