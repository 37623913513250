import React from "react"
import './index.css'
import { FormattedMessage } from 'react-intl'
export default function ContentBlock() {
    return (
        <section className="content-block">
            <div className="container">
                <h4 className="like-header">
                    <FormattedMessage id="header4" defaultMessage="Massage for mens" />
                </h4>
                <p><FormattedMessage id="asktocome" defaultMessage="Write your phone" /></p>

                <a href="tel:tel:+380986684173" className="form-button"><FormattedMessage id="asktocall" defaultMessage="Call me!" /></a>

                <p><FormattedMessage id="footertext" defaultMessage="Erotic massage in Lviv" /></p>
            </div>
        </section>

    )
}