import React from 'react';
import './App.css';
import ContentBlock from './components/ContentBlock';
import FirstScreenBlock from './components/FirstSceenBlock';
import FooterBlock from './components/FooterBlock';
import HeaderBlock from './components/HeaderBlock';
import LadiesBlock from './components/LadiesBlock';
import MenuBlock from './components/MenuBlock';
import PriceBlock from './components/PriceBlock';
import { BrowserRouter, Route } from "react-router-dom"



export default function App() {
  return (
    <BrowserRouter>
      <main>
        <HeaderBlock></HeaderBlock>
        <MenuBlock></MenuBlock>

        <FirstScreenBlock></FirstScreenBlock>
        <LadiesBlock></LadiesBlock>
        <PriceBlock></PriceBlock>
        <ContentBlock></ContentBlock>
        <FooterBlock></FooterBlock>
      </main>
    </BrowserRouter>
  );
}
